const fitnessCopy = {
  about: {
    de: {
      title: 'Personal Training',
      body: [
        'Fühlst du dich wohl, bist du gesund und stark, dann hast du die Freiheit alles zu tun, was du möchtest!',
        `Du willst:
        <ul>
        <li>Muskulatur aufbauen</li>
        <li>Körperfett verlieren</li>
        <li>deine Leistungsfähigkeit verbessern</li>
        <li>deinen Booty shapen</li>
        <li>dein Wohlbefinden und Selbstwertgefühl auf ein neues Level bringen</li>
        </ul>
        `,
        '- 99+% der Menschen sollten alle genannten Punkte anstreben - egal wo man sich gerade befindet -'
      ]
    },
    en: {
      title: 'Personal Training',
      body: [
        'If you feel good, are healthy and strong, then you have the freedom to do anything you want!',
        `You want to:
        <ul>
        <li>build muscle</li>
        <li>lose body fat</li>
        <li>improve your performance/work capacity</li>
        <li>shape your booty</li>
        <li>take your well-being and self-esteem to a new level</li>
        </ul>
        `,
        '- 99+% of people should strive for all of the above - no matter where you are -'
      ]
    }
  },
  whatnext: {
    de: {
      title1: 'Ja, ich will! Wie gehts weiter?',
      body1: [
        'Du kontaktierst mich!',
        'Erstes unverbindliches Beratungsgespräch/ Probetraining',
        'Wir legen die passende Methode für deine Bedürfnisse fest! (Personal Training, Online Coaching, Erstellung eines individuellen Trainingsplans)',
        `Let's go!`
      ],
      title2: 'Was benötigst du?',
      body2: [
        'Den Willen an dir zu arbeiten!'
      ]
    },
    en: {
      title1: 'Yes, I do! What happens next?',
      body1: [
        'Contact me!',
        'Noncommittal consultation/trial session',
        'We identify the right method for your needs! (personal training, online coaching, creation of an individual training plan)',
        `Let's go!`
      ],
      title2: 'What do you need?',
      body2: [
        'The commitment to work on yourself!'
      ]
    },
  },
  info: {
    de: {
      title1: 'Mach dich selbst zu deinem Lieblingsoutfit!',
      body1: [
        'Etwas Disziplin und Struktur gepaart mit einigen schlauen Entscheidungen und du kannst die beste Version von dir selbst erschaffen - du machst dich selbst zu deinem Lieblingsoutfit. Jenes welches nicht nur passt wie angegossen, in welchem du einfach immer top aussiehst; noch viel wichtiger - jenes, in dem du dich einfach wohl fühlst. Ich liebe diese Idee!',
        'Zusammen erarbeiten wir einen Plan für DICH, welcher dich langfristig und nachhaltig an dein Ziel bringen wird.',
        `Dieses kann und wird sich im Laufe des Prozesses, so wie du dich selbst, verändern - und das ist eine gute Sache.
        „Bleib so wie du bist.“ - Was soll der Scheiß?! Bloß nicht… Wir wollen Veränderung. Veränderung bedeutet Arbeit - das bedeutet es wird nicht immer Spaß machen - aber alles ist besser als stillzustehen. 
        Stillstand ist immer Rückschritt - die Erde dreht sich weiter. Stillstand ist der Tod!
        Ja, irgendwann verlieren wir alle das Rennen gegen die Zeit - wie du dieses gestaltest, bleibt aber dir und nur dir selbst überlassen!
        Also lass uns in Bewegung kommen und gemeinsam wachsen, etwas verändern - lass uns Dein Rennen endlich starten, einfach weil DU es kannst!`,
        'Entscheidest du dich, dich zu deinem Lieblingsoutfit zu machen, dann legst du das Fundament für etwas Großes. Du entscheidest dich frei zu sein!'
      ],
      title2: 'Wie komme ich dazu mir die Freiheit zu nehmen…',
      body2: [
        `…dir zu sagen was du machen sollst und dich als Coach zu betreuen? Unabhängig von meinem Götterkomplex und der Angst vor ehrlicher Arbeit :D
        Im Jahr 2009 ist es passiert. Ich betrat zum ersten mal ein Fitnessstudio und es war um mich geschehen. Liebe auf den ersten Blick. Seit dem strebe ich tagtäglich nach der Optimierung meiner Körperzusammensetzung und dessen Leistungsfähigkeit! Warum? Einfach weil es geil ist!`,
        `Nach einem kurzen Intermezzo im Studium der Kunststofftechnik, akzeptierte ich den Fakt, dass Eisen besser ist und ging nach Wien um es dort noch regelmäßiger zu biegen. 
        Ein halbes Sportstudium, eine Fitness- und Personaltrainer Ausbildung später begann ich Mitte 2015 als Personal Trainer zu arbeiten. Seitdem helfe ich Menschen dabei ihr Leben zu verbessern und darf auch selbst ständig weiterlernen. Es folgten Ausbildungen zum Dipl. Strength Coach und im NLP, sowie Einblicke wie auch Arbeit im Sports Performance Bereich.`,
        'Privat springe ich gerne aus Flugzeugen, stürze mit meinem Mountainbike, liebe Musik, Kunst, Pizza, Eis und - richtig geraten - biege noch mehr Eisen als zuvor.',
        `Ich gehe davon aus du bist nun mehr als überzeugt davon, dass ich der Richtige für dich bin. 
        Na dann; Ich freu mich auf deine Anfrage.`
      ]
    },
    en: {
      title1: 'Make yourself your favourite outfit!',
      body1: [
        'A little discipline and structure coupled with some smart decisions and you can create the best version of yourself - you make yourself your favourite outfit. The one that not only fits you like a glove and the one you always look great in, but most importantly, the one you feel good in. I love this idea!',
        `Together we will work out a plan for YOU, which will move you towards your goal in the long term and in a sustainable way.
        This goal can and will change throughout the process, just like you - and that's a good thing.
        "Stay the way you are." - What the fuck!!! Just don't... We want change. Creating change requires work, which means it won't always be fun, but anything is better than standing still. 
        Standing still is always going backwards - the earth keeps rollin' rollin' rollin' rollin'... Standing still means death!
        Yes, at some point we all lose the race against time - but how you run it is up to you and you alone!
        So let's get moving and grow together, change something. Let's finally start your race, simply because YOU can!`,
        'If you decide to make yourself your favourite outfit, then you are laying the foundation for something bigger. You decide to be free!'
      ],
      title2: 'So how do I take the freedom…',
      body2: [
        `…to tell you what to do and coach you? - irrespective of my god complex and the fear of honest work :D ...
        It all started in 2009. I walked into a gym for the first time. Love at first sight. Since then I strive to optimize my body composition and its performance every day! Why? Simply because it's awesome!`,
        `After a short intermezzo in the study of plastics engineering, I accepted the fact that iron is better and went to Vienna to bend it even more regularly. 
        Half a sports science degree, a fitness and personal trainer education later, I started working as a personal trainer in mid-2015. Since then I help people to improve their lives and am able to continue growing my knowledge. Following education as a cert. Strength Coach and in neurolinguistic programming, I gained insights in the sports performance field.`,
        'In my private life I like to jump out of airplanes, crash my mountain bike, love music, art, pizza, ice cream and - rightly guessed - bend even more iron than before.',
        `I assume you are now more than convinced that I am the right one for you. 
        Well then; I look forward to your request.`
      ]
    }
  }
}

export default fitnessCopy
import React from 'react'
import Layout from '../components/Layout'
import img from '../images/markus.jpg'
import fitnessCopy from '../data/fitnessCopy'
import AboutTrainer from '../components/individual/AboutTrainer'
import WhatNext from '../components/individual/WhatNext'
import AdditionalInfo from '../components/individual/AdditionalInfo'
import bottomImg from '../images/fitness-bottom.jpg'

const FitnessPage = () => {
  const locations = [
    {
      line1: 'Stefan Esders Platz 7,',
      line2: '1190 Wien'
    },
    {
      line1: 'Neutorgasse 16,',
      line2: '1010 Wien'
    }
  ]
  return (
    <Layout pageTitle="BMI | Fitness">
      <AboutTrainer trainer='Markus' copy={fitnessCopy.about} lang img={img} locations={locations}/>
      <WhatNext copy={fitnessCopy.whatnext} lang/>
      <AdditionalInfo copy={fitnessCopy.info} img={bottomImg} lang/>
    </Layout>
  )
}

export default FitnessPage